"use client";

import dynamic from "next/dynamic";
import { useEffect, useState, useRef } from "react";

const WhyAmityCard = dynamic(() => import("@/revamp/sections/WhyAmityCard"), { ssr: false });
const DegreeProgramsSection = dynamic(() => import("@/revamp/sections/DegreeProgram"), { ssr: false });
const StudentTestimonial = dynamic(() => import("@/revamp/sections/StudentTestimonial"), { ssr: false });
const BrandLogos = dynamic(() => import("@/revamp/sections/BrandLogos"), { ssr: false });
const CareerServices = dynamic(() => import("@/components/sections/CareerServices"), { ssr: false });
const FacultySection = dynamic(() => import("@/components/sections/Faculties"), { ssr: false });
const NewsStoriesSection = dynamic(() => import("@/revamp/sections/NewsStories"), { ssr: false });
const NextStepCareerSection = dynamic(() => import("@/revamp/sections/NextStepCareer"), { ssr: false });
const FaqWithForm = dynamic(() => import("@/revamp/sections/FaqSection"), { ssr: false });


const renderComponent = (keyName, props) => {

  switch (keyName) {
    case "WhyAmityCard":
      return <WhyAmityCard  {...props} />
  
    case "DegreeProgramsSection":
      return <DegreeProgramsSection  {...props} />

    case "StudentTestimonial":
      return  <StudentTestimonial {...props}/>
     
    case "BrandLogos":
      return <BrandLogos {...props} />
      
    case "CareerServices":
      return <CareerServices {...props} />
    
    case "FacultySection":
      return <FacultySection {...props} />
      
    case "NewsStoriesSection":
      return <NewsStoriesSection {...props} />
      
    case "NextStepCareerSection":
      return <NextStepCareerSection  {...props} />
  
    case "FaqWithForm":
      return <FaqWithForm  {...props} />
  
    default:
      return null
  }
}


export default function LazyLoadSection({ rootMargin = "200px", type = null, componentProps={} }) {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        }
      },
      { rootMargin }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, [rootMargin]);

  return (
    <div ref={sectionRef}>
      {inView ? renderComponent(type, componentProps) : (
        <div style={{ minHeight: "300px" }}>
          {/* Optional Skeleton Loader */}
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
}